const host = "https://gcqtqi0341.execute-api.eu-north-1.amazonaws.com/stage/";
const externalHost = "https://demo.svenskafribrevsbolaget.se";
export default {
  env: "stage",
  domain: host,
  BASE_URL: host,
  EXTERNAL_URL: externalHost,
  baseName: "/",
  language: "sv",
  markerDestinationId: "6568a81f51c10145c25d62c1",
  api: {
    maxAge: 15 * 60 * 1000, // 15 minutes
  },
};
